import React from 'react'
import { StandardPage } from 'src/features/templates'
import SEO from 'src/components/seo'
import DownloadsPage from './DownloadsPage'

const GatsbyCheckoutPage = () => (
  <StandardPage>
    <SEO title="My Downloads" />
    <DownloadsPage />
  </StandardPage>
)

export default GatsbyCheckoutPage
