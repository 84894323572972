import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import GET_DOWNLOADS from '../../download/data/getDownloads'

const Downloads = ({ email }) => {
  const { data, loading, error } = useQuery(GET_DOWNLOADS, {
    variables: { email },
    skip: !email,
  })

  if (loading) return 'Loading...'
  if (error) return `An error has occurred: ${error}`

  const { itemCount } = data?.downloads || {}

  return (
    <>
      <h2>Success! Be sure to check your email.</h2>
      You have purchased <b>{itemCount} items</b> that are downloadable.
      Temporary download links have been sent to your e-mail address. IMPORTANT:
      These links expire in 24 hours, so be sure to download them right away. If
      these expire, you&apos;ll have to come back to this page to regenerate
      them and receive a new e-mail.
    </>
  )
}

export default Downloads
