import React, { useState } from 'react'
import qs from 'qs'
import Downloads from './Downloads'

const DownloadsPage = () => {
  const [emailInputVal, setEmailInputVal] = useState('')
  const [email, setEmail] = useState(
    typeof window !== `undefined` &&
      qs.parse(window.location.search.replace('?', ''))?.email,
  )

  const handleSubmit = () => {
    setEmail(emailInputVal)
    setEmailInputVal('')
  }

  return (
    <>
      <h1>My Downloads</h1>

      {email ? (
        <Downloads email={email} />
      ) : (
        <>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="email">Email address:&nbsp;&nbsp;</label>
          <input
            name="email"
            id="email"
            value={emailInputVal}
            onChange={e => setEmailInputVal(e.target.value)}
          />
          <br />
          <button type="submit" onClick={handleSubmit}>
            Request downloads
          </button>
        </>
      )}
    </>
  )
}

export default DownloadsPage
